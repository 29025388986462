import { Button, Layout } from "antd";
import { IPublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated } from "@azure/msal-react";
import "./LogIn.css";
import Header from "../../components/layout/header/Header";
import { ThemeTypes } from "../../types/types";
import { Colors } from "../../constants/Colors";
import { loginRequest } from "../../auth/authConfig";

interface LogInProps {
  instance: IPublicClientApplication;
}

const LogIn = ({ instance }: LogInProps) => {
  const isAuthenticated = useIsAuthenticated();

  const handleLogIn = () => {
    if (!isAuthenticated) {
      instance.loginPopup(loginRequest).catch((err) => console.log(err));
    }
  };

  return (
    <Layout>
      <Header theme={ThemeTypes.LIGHT} isLoggedIn={false} />
      <Layout.Content
        className="login-content-container"
        style={{ backgroundColor: Colors.lightWhite }}
      >
        <div
          className="login-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="login-logo-container">
            <img alt="logo" src={require("../../assets/images/logo.png")} />
          </div>
          <p className="login-title">
            Admin First
            <br />
            <span>Product and Service</span>
          </p>
          <div className="login-sub-title">Log in with</div>
          <Button
            type="primary"
            size="large"
            icon={
              <img
                alt="365 logo"
                src={require("../../assets/icons/365.png")}
                style={{ width: 14, height: 14 }}
              />
            }
            onClick={handleLogIn}
          >
            WFE Microsoft account
          </Button>
        </div>
        <div className="login-footer-container">
          <p>Version 1.9.0</p>
          <p>&copy; 2023 World Family English Ltd.</p>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default LogIn;
