import { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Drawer,
  Form,
  List,
  Modal,
  Radio,
  Select,
  Table,
  Upload,
  message,
  Input,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { PlusOutlined, UploadOutlined, InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import axios from "axios";
import "./EventBannerTemplate.css";
import { Colors } from "../../../../constants/Colors";
import { data } from "../../../../data/data";
import DrawerFooter from "../../../../components/layout/drawer/DrawerFooter";
import InputCustom from "../../../../components/custom/InputCustom";
import { renderAntDMessageConfig } from "../../../../utils/renderAntDMessageConfig";
import Spinner from "../../../../components/layout/spinner/Spinner";
import uploadFileToClubServiceBlob from "../../../../utils/uploadFileToClubServiceBlob";
import { useUploadFileMutation } from "../../../../graphql/operations/upload-file";
import { AfBlobPath } from "../../../../graphql/operations/@types";
import requiredRule from "../../../../utils/formRules/requiredRule";
import { FormInstance } from "antd";

interface EventBannerTemplateProps {
  apiUri: string | undefined;
}

interface DataType {
  id: string;
  bannerType: string;
  eventType: string;
  image: string;
  fileName: string;
  templateName: string;
}

const { bannerTypes, bannerEventTypes } = data;
const { Dragger } = Upload;

const EventBannerTemplate = ({ apiUri }: EventBannerTemplateProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectDefaultType, setSelectDefaultType] = useState("MP");
  const [displayData, setDisplayData] = useState<DataType[]>([]);
  const [selectBanner, setSelectBanner] = useState<DataType | undefined>();
  const [showDrawer, setShowDrawer] = useState(false);
  const [showUploadModal1, setShowUploadModal1] = useState(false);
  const [showUploadModal2, setShowUploadModal2] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<any>("");
  const [uploadFileList, setUploadFileList] = useState<any>([]);
  const [batchUploadPayload, setBatchUploadPayload] = useState<any>([]);
  const [form] = Form.useForm();
  const [batchForm] = Form.useForm();

  const [uploadFile] = useUploadFileMutation();

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const getTemplatesByType = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${apiUri}/app/banner-list/${selectDefaultType}`,
        {
          headers: { "user-code": "P606" },
        }
      );
      const transformedData = data.map((item: any, i: any) => ({
        id: item.seq.toString(),
        bannerType: item.typeNo,
        eventType: item.typeEnglishName,
        image: item.imageUrl,
        fileName: item.imageUrl,
        templateName: item.textContent,
      }));
      setDisplayData(transformedData);
      setIsLoading(false);
    } catch (error) {
      message.error(
        renderAntDMessageConfig(
          "Something went wrong. Please contact your system administrator."
        )
      );
      setIsLoading(false);
    }
  }, [apiUri, selectDefaultType]);

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    try {
      await axios.delete(`${apiUri}/app/banner/${id}`, {
        headers: {
          "User-Code": "P606",
        },
      });
      getTemplatesByType();
    } catch (error) {
      message.error(
        renderAntDMessageConfig(
          "Something went wrong. Please contact your system administrator."
        )
      );
      setIsLoading(false);
    }
  };

  const columns: ColumnsType<DataType> = [
    // {
    //   title: "Event type",
    //   dataIndex: "eventType",
    //   key: "eventType",
    //   width: 300,
    //   sorter: (a, b) => a.eventType.localeCompare(b.eventType),
    //   filters,
    //   onFilter: (value: any, record: DataType) =>
    //     record.eventType.indexOf(value) === 0,
    // },
    {
      title: "Template name",
      dataIndex: "templateName",
      key: "templateName",
      width: 300,
      sorter: (a, b) => a.templateName.localeCompare(b.templateName),
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (value: string) => (
        <img alt="banner" src={value} style={{ width: 112 }} />
      ),
      sorter: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (value: string, record: DataType) => (
        <>
          <Button
            type="link"
            onClick={() => {
              setSelectBanner(record);
              setShowDrawer(true);
            }}
            style={{ padding: 0, marginRight: 16 }}
          >
            Edit
          </Button>
          <Button
            type="link"
            onClick={() => handleDelete(value)}
            style={{ padding: 0, marginRight: 16 }}
          >
            Delete
          </Button>
        </>
      ),
      width: 200,
    },
  ];

  const singleUploadProps: UploadProps = {
    name: "file",
    action: "https://httpbin.org/post",
    headers: {
      authorization: "authorization-text",
    },
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Only upload image file!"));
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange(info) {
      setUploadedFileName(info.file.name);
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        getBase64(info.file.originFileObj, async () => {
          const newFileName = await uploadFileToClubServiceBlob(
            info.file.originFileObj,
            "appEventBanner",
            "P606"
          );
          message.success(
            renderAntDMessageConfig(
              `${info.file.name} file uploaded successfully`
            )
          );
          setUploadedFileName(newFileName);
        });
      } else if (info.file.status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
    },
    maxCount: 1,
    listType: "picture",
    onRemove: () => setUploadedFileName(""),
  };

  const batchUploadProps: UploadProps = {
    name: "file",
    multiple: true,
    action: "https://httpbin.org/post",
    beforeUpload: (file) => {
      const isImage = file.type.startsWith("image");
      if (!isImage) {
        message.error(renderAntDMessageConfig("Only upload image file!"));
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange(info) {
      if (info.fileList.length === 0) {
        setUploadFileList([]);
        setBatchUploadPayload([]);
      }
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        setUploadFileList(
          info.fileList.map((item) => {
            return {
              fileName: item.name,
              imageUrl: item?.response?.files?.file,
            };
          })
        );
        setBatchUploadPayload(
          info.fileList.map((item) => {
            return {
              typeNo: selectDefaultType,
              imageNames: [item.name],
              textContent: "",
              disabledFlag: false,
            };
          })
        );
      }
      if (status === "done") {
        message.success(
          renderAntDMessageConfig(
            `${info.file.name} file uploaded successfully.`
          )
        );
        setUploadFileList(
          info.fileList.map((item) => {
            return {
              fileName: item.name,
              imageUrl: item?.response?.files?.file,
            };
          })
        );
        setBatchUploadPayload(
          info.fileList.map((item) => {
            return {
              typeNo: selectDefaultType,
              imageNames: [item.name],
              textContent: "",
              disabledFlag: false,
            };
          })
        );
      } else if (status === "error") {
        message.error(
          renderAntDMessageConfig(`${info.file.name} file upload failed.`)
        );
      }
      uploadFile({
        variables: {
          blobs: info.fileList.map((item) => {
            return {
              file: item.originFileObj,
              path: AfBlobPath.AppEventBannerImage,
            };
          }),
        },
      }).catch((e) => console.log(e));
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleSubmit = async (values: any) => {
    if (selectBanner) {
      setIsLoading(true);
      const updatedData = {
        seq: selectBanner.id,
        typeNo: selectBanner.bannerType,
        imageName: uploadedFileName,
        textContent: values.templateName,
        disabledFlag: false,
      };
      try {
        await axios.patch(`${apiUri}/app/banner`, updatedData, {
          headers: {
            "User-Code": "P606",
          },
        });
        message.success(
          renderAntDMessageConfig("Update template banner successful")
        );
        getTemplatesByType();
        form.resetFields();
        setSelectBanner(undefined);
        setShowDrawer(false);
        setIsLoading(false);
      } catch (error) {
        message.error(
          renderAntDMessageConfig(
            "Something went wrong. Please contact your system administrator."
          )
        );
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      const newData = {
        typeNo: values.bannerType,
        imageNames: [uploadedFileName],
        textContent: values.templateName,
        disabledFlag: false,
      };
      try {
        await axios.post(`${apiUri}/app/banner`, newData, {
          headers: {
            "User-Code": "P606",
          },
        });
        message.success(
          renderAntDMessageConfig("Create new template banner successful")
        );
        getTemplatesByType();
        form.resetFields();
        setSelectBanner(undefined);
        setShowDrawer(false);
        setIsLoading(false);
      } catch (error) {
        message.error(
          renderAntDMessageConfig(
            "Something went wrong. Please contact your system administrator."
          )
        );
        setIsLoading(false);
      }
    }
  };

  const handleBatchSubmit = () => {
    setIsLoading(true);
    batchUploadPayload.forEach(async (item: any, index: number) => {
      try {
        await axios.post(`${apiUri}/app/banner`, item, {
          headers: {
            "User-Code": "P606",
          },
        });
        if (index === batchUploadPayload.length - 1) {
          message.success(
            renderAntDMessageConfig("Create new template banner successful")
          );
          getTemplatesByType();
          form.resetFields();
          setSelectBanner(undefined);
          setShowDrawer(false);
          setIsLoading(false);
        }
      } catch (error) {
        message.error(
          renderAntDMessageConfig(
            "Something went wrong. Please contact your system administrator."
          )
        );
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getTemplatesByType();
  }, [getTemplatesByType]);

  useEffect(() => {
    if (selectBanner) {
      setUploadedFileName(
        selectBanner.fileName.split("?")[0].split("-img/")[1]
      );
      form.setFieldsValue({
        eventType: selectBanner.eventType,
        templateName: selectBanner.templateName,
        imageFile: [
          {
            uid: "0",
            name: selectBanner.fileName.split("?")[0].split("-img/")[1],
            status: "done",
            url: selectBanner?.image,
            thumbUrl: selectBanner?.image,
          },
        ],
      });
    }
  }, [selectBanner, form]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="theme-tab-content-container">
        <div
          className="theme-tab-table-container"
          style={{ backgroundColor: Colors.white }}
        >
          <div className="banner-header-container">
            <Radio.Group
              options={bannerTypes.map((type) => ({
                label: type.label,
                value: type.type,
              }))}
              optionType="button"
              onChange={(e) => setSelectDefaultType(e.target.value)}
              defaultValue="MP"
              value={selectDefaultType}
              style={{ marginBottom: 24 }}
            />
            <div className="theme-tab-header-container">
              <Button
                icon={<UploadOutlined />}
                onClick={() => setShowUploadModal1(true)}
                style={{ marginRight: 8 }}
              >
                Batch Upload
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowDrawer(true)}
              >
                Create New
              </Button>
            </div>
          </div>
          <Table
            rowKey={(record: DataType) => record.id}
            columns={columns}
            dataSource={displayData}
            pagination={false}
            className="announcement-table"
            scroll={{ x: true, y: window.innerHeight * 0.7 - 48 - 55 }}
          />
        </div>
      </div>
      <Drawer
        title={
          selectBanner
            ? "Edit template event banner"
            : "Create new template event banner"
        }
        open={showDrawer}
        onClose={() => {
          form.resetFields();
          setSelectBanner(undefined);
          setShowDrawer(false);
        }}
        width={500}
      >
        <Form
          name="template-banner-form"
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item label="Event category" name="bannerType">
            {selectBanner ? (
              <p style={{ marginTop: -16, color: "#071491" }}>
                {
                  bannerTypes.find(
                    (type) => type.type === selectBanner?.bannerType
                  )?.label
                }
              </p>
            ) : (
              <p style={{ marginTop: -16, color: "#071491" }}>
                {bannerTypes.find((i) => i.type === selectDefaultType)?.label}
              </p>
            )}
          </Form.Item>
          {/* <Form.Item
            label="Event type"
            name="eventType"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <Select
              placeholder="- Select -"
              options={bannerEventTypes
                .find((cat) => cat.category === selectDefaultType)
                ?.types.map((type) => ({
                  label: type.type,
                  value: type.type,
                }))}
            />
          </Form.Item> */}
          <Form.Item
            label="Template name"
            name="templateName"
            rules={[{ required: true, message: "This field is required!" }]}
          >
            <InputCustom />
          </Form.Item>
          <Form.Item
            label="Image"
            name="imageFile"
            rules={[{ required: true, message: "This field is required!" }]}
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload {...singleUploadProps} className="banner-upload">
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
          <DrawerFooter
            handleCancel={() => {
              form.resetFields();
              setSelectBanner(undefined);
              setShowDrawer(false);
            }}
          />
        </Form>
      </Drawer>
      <Modal
        open={showUploadModal1}
        onCancel={() => {
          setShowUploadModal1(false);
        }}
        footer={
          <Button
            type="primary"
            onClick={() => {
              setShowUploadModal1(false);
              setShowUploadModal2(true);
            }}
            disabled={uploadFileList.length === 0}
          >
            Next
          </Button>
        }
        title={
          <div style={{ borderBottom: "1px solid #F0F0F0" }}>
            <p style={{ padding: "0px 24px 20px" }}>
              Batch upload template event banner
            </p>
          </div>
        }
        centered
        className="batch-container"
        bodyStyle={{ padding: "16px 24px 8px" }}
      >
        <Dragger {...batchUploadProps}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint" style={{ marginTop: 12 }}>
            Support for a single or bulk upload
          </p>
          <p className="ant-upload-hint" style={{ margin: "24px 0px 0px" }}>
            Recommended file size less than 100KB
          </p>
        </Dragger>
      </Modal>
      <Modal
        open={showUploadModal2}
        onCancel={() => {
          setShowUploadModal2(false);
        }}
        centered
        className="batch-container"
        title={
          <div style={{ borderBottom: "1px solid #F0F0F0" }}>
            <p style={{ padding: "0px 24px 20px" }}>
              Batch upload template event banner
            </p>
          </div>
        }
        bodyStyle={{ padding: "16px 24px 8px" }}
        width={1032}
        footer={
          <Button
            type="primary"
            onClick={() => {
              batchForm.validateFields().then(() => {
                setSelectBanner(undefined);
                setShowUploadModal2(false);
                handleBatchSubmit();
              });
            }}
          >
            Submit
          </Button>
        }
      >
        <Form layout="vertical" form={batchForm}>
          <List
            grid={{
              gutter: 16,
              column: 4,
            }}
            dataSource={uploadFileList}
            className="batch-list-container"
            renderItem={(item: any, index) => {
              return (
                <List.Item>
                  <Card>
                    <img
                      alt="banner"
                      src={item.imageUrl}
                      style={{ maxWidth: "100%" }}
                    />

                    <Form.Item
                      label="Template name"
                      rules={[requiredRule]}
                      name="template"
                      style={{ padding: "0 12px", marginTop: 10 }}
                    >
                      <Input
                        onChange={(e: any) => {
                          const replica = batchUploadPayload;
                          replica[index].textContent = e.target.value;
                          setBatchUploadPayload(replica);
                        }}
                      />
                    </Form.Item>
                  </Card>
                </List.Item>
              );
            }}
          />
        </Form>
      </Modal>
    </>
  );
};

export default EventBannerTemplate;
